// --------------------------------------------------------
// Main Style
// --------------------------------------------------------
@import "layout/main_style";


// --------------------------------------------------------
/* Vendor */
// --------------------------------------------------------
@import "vendor/swiper/swiper";
@import "vendor/_jquery.autocomplete.min.scss";



// --------------------------------------------------------
/* Container */
// --------------------------------------------------------
// Product List (All) | 所有產品列表
.product-list-group {
	$root: &;
	margin-bottom: rem-calc(40);
	@include breakpoint(medium) {
		margin-bottom: rem-calc(32); }
	a {
		display: block; }
	&__title-box {
		margin-bottom: rem-calc(30);
		padding-bottom: rem-calc(16);
		border-bottom: 4px solid #e5e7eb;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include breakpoint(medium only) {
			min-height: 59px; } } // 為了要對齊線段高
	&__title {
		font-size: rem-calc(16);
		font-weight: bold;
		margin-bottom: 0;
		@include breakpoint(medium) {
			font-size: rem-calc(20); }
		&__link {
			color: $black;
			@include breakpoint(small only) {
				font-size: rem-calc(14); } } }
	&__select-box {
		$item: &;
		margin-bottom: 0;
		width: 175px + 50;
		max-width: calc(50% - 5px);
		justify-content: flex-end;
		select {
			margin-bottom: 0; }
		.button {
			width: rem-calc(40);
			margin: 0;
			padding: rem-calc(10 8);
			margin-left: rem-calc(10);
			white-space: nowrap; }
		@include breakpoint(small only) {
			font-size: rem-calc(16); }
		@include breakpoint((1300 - 1) down) {
			.button {
				.text {
					@include element-invisible; } } }
		@include breakpoint-between(large, 1300 - 1) {
			max-width: 220px; }
		@include breakpoint(1300) {
			width: 360px;
			flex: 0 0 360px;
			select {
				width: 180px; }
			.button {
				width: rem-calc(150);
				padding: rem-calc(10 16);
				margin-left: rem-calc(15);
				.be-icon {
					display: none; }
				.text {
					display: block; } } } } }

// Product List (All) | 所有產品列表
.product-list-all {
	$root: &;
	margin-bottom: 0;
	list-style: none;
	&__item {
		margin-bottom: rem-calc(24); }
	// 子項目
	&__link {
		font-size: rem-calc(15);
		text-align: center;
		color: $black; }
	&__img-box {
		margin-bottom: rem-calc(12);
		border: 1px solid #e8eaef; }
	@include breakpoint(large) {
		// Hover
		&__img-box {
			overflow: hidden; }
		&__img {
			transition: transform $tsd; }
		&__link {
			&:hover,
			&:focus {
				#{$root} {
					&__img {
						transform: scale(1.05); } } } } } }

// --------------------------------------------------------
// 產品列表
// 左欄
// 選單相關
.product-list-nav {
	$root: &;
	margin-bottom: rem-calc(20);

	// 左欄 - 分類主標題
	&__main-title {
		display: block;
		margin-bottom: rem-calc(13);
		font-size: rem-calc(20);
		color: $black;
		&.is-active {
			font-weight: bold;
			position: relative;
			padding-right: 2em;
			&:after {
				@include font-icon-arrow-right;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				color: $primary-color; } }
		@include breakpoint(medium) {
			margin-bottom: rem-calc(20); } }


	// 左欄 - 主內容(連結/篩選)
	&__main-cont {
		@include breakpoint(small only) {
			margin: rem-calc(0 -5 10);
			display: flex;
			flex-wrap: wrap; } }


	// 左欄 - 提醒區(TV使用)
	&__tip {
		font-size: rem-calc(14);
		.em {
			font-size: rem-calc(15); }
		a {
			color: $black; }
		p {
			margin-bottom: rem-calc(12); }
		hr {
			margin: rem-calc(12 0); }
		@include breakpoint(medium) {
			margin-top: rem-calc(20); } }

	@at-root .no-js #{$root} {
		&__main-cont {
			margin-bottom: rem-calc(24); } } }



// 為了手機板展開的結構
// 只有手機板有介面，medium以上要分別寫
$fixedPanelMClassName: fixed-panel-m;
$disabledDisabledColor: #6e6b79;
.#{$fixedPanelMClassName} {
	$root: &;
	&__ft {
		.button {
			&,
			&:hover,
			&:focus {
				&,
				&[disabled] {
					border-width: 2px; } }
			&[disabled] {
				background: $disabledDisabledColor;
				border-color: $disabledDisabledColor;
				opacity: 1;
				&.hollow {
					color: $disabledDisabledColor;
					background: transparent;
					&:hover,
					&:focus {
						border-color: $disabledDisabledColor; } } } } }
	@include breakpoint(small only) {
		&__panel {
			position: fixed;
			z-index: 1000;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $white;
			display: none;
			animation: showPanel .3s;
			&.is-open {
				display: block; } }
		&__hd,
		&__ft {
			padding: rem-calc(10);
			position: fixed;
			z-index: 10;
			left: 0;
			width: 100%;
			background: $white; }
		&__hd {
			background: #e7e9ec;
			top: 0; }
		&__ft {
			bottom: 0;
			padding-left: rem-calc(5);
			padding-right: rem-calc(5);
			text-align: center;
			display: flex;
			.button {
				margin: rem-calc(0 5); } }
		&__cont {
			margin-top: rem-calc(50);
			height: calc(100vh - #{rem-calc(50 + 60)}); // Header: 50px, Footer: 60px
			overflow: auto;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch; }
		&__title {
			font-size: rem-calc(20);
			font-weight: bold; } }

	@keyframes showPanel {
		from {
			opacity: 0;
			transform: translateY(100%); } }

	// -----------------
	// 左側欄位篩選/連結
	&[data-layout="aside"] {
		$item: &;
		@include breakpoint(small only) {
			margin: rem-calc(0 5 10);
			flex: 1;
			width: calc(50% - #{rem-calc(10)});
			#{$root} {
				&__btn {
					padding: rem-calc(10);
					font-size: rem-calc(14);
					color: $primary-color;
					border: 2px solid $primary-color;
					text-align: center;
					cursor: pointer;
					small {
						opacity: 0.8; } } } }
		@include breakpoint(medium) {
			#{$root} {
				&__btn,
				&__hd,
				&__ft {
					display: none; } } }

		// 特殊呈現
		// 手機版本呈現icon
		&.m-icon {
			@include breakpoint(small only) {
				flex: 0 0 46px; } }
		// 手機版本隱藏標題
		&.m-hide-title {
			@include breakpoint(small only) {
				.product-list-nav-block__title {
					display: none; } } }

		// 各類別呈現
		// 分類
		&.cate {
			#{$root} {
				&__btn {
					background: $primary-color;
					color: $white; } } }
		&.past {
			#{$root}__btn {
				i:before {
					transform: scale(1.5); } } }

		// NO JS
		@include breakpoint(medium) {
			@at-root .no-js #{$item}.filter {
				#{$root} {
					&__ft {
						display: block;
						button[type="reset"] {
							display: none; } } } } } } }



// 左欄 區塊
.product-list-nav-block {
	$root: &;
	@include breakpoint(small only) {
		padding: rem-calc(0 10);
		margin-bottom: rem-calc(20); }
	&__title {
		padding: rem-calc(8 0);
		font-size: rem-calc(20);
		font-weight: bold;
		border-bottom: 4px solid #e7e9ec; }

	@at-root .no-js #{$root} {
		&__cont {
			& > .product-list-filter-reset-global {
				display: none; }
			.product-list-filter-reset-area {
				display: none; } } } }



// 左欄 連結
.product-list-link {
	$root: &;
	@include breakpoint(medium) {
		margin-top: rem-calc(10); }
	&__link {
		color: $black;
		display: block;
		padding: rem-calc(12 0);
		padding-right: rem-calc(30);
		@include breakpoint(medium) {
			padding-top: rem-calc(5);
			padding-bottom: rem-calc(5); }
		&.disable {
			color: #999999;
			pointer-events: none; } }
	&__item {
		// Active
		&.is-active {
			#{$root} {
				&__link {
					position: relative;
					font-weight: bold;
					&:after {
						@include font-icon-arrow-right;
						position: absolute;
						right: -5px;
						top: 50%;
						transform: translateY(-50%);
						color: $primary-color; } } } } } }



// 左欄 篩選 | Filter Panel(Checkbox)
.product-list-filter-panel {
	$root: &;
	@include breakpoint(medium) {
		margin-bottom: rem-calc(30); }
	&__item {
		&:last-child:not(.is-active) > .accordion-title,
		&:last-child > .accordion-content {
			border: 0 none; } }
	&__title {
		padding: rem-calc(20 0);
		font-size: rem-calc(16);
		border: none;
		border-bottom: 1px solid #e5e7eb;
		color: $black;
		&:hover,
		&:focus {
			background: $white; }
		&:before {
			display: none; }
		&:after {
			@include font-icon-arrow-right;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(-10px, -50%);
			color: $primary-color;
			opacity: 0;
			transition: opacity $tsd, transform $tsd; } }
	&__cont {
		border: none;
		border-bottom: 1px solid #e5e7eb;
		padding: rem-calc(10 0 20);
		overflow: auto; }

	&__count {
		position: absolute;
		right: 0;
		top: calc(50% - .5em);
		white-space: nowrap;
		transition: transform $tsd; }

	// Active
	&__item {
		&.is-active {
			#{$root} {
				&__title {
					&:after {
						opacity: 1;
						transform: translate(0, -50%); } }
				&__count {
					transform: translateX(-1.5rem); } } } }

	&__close-panel {
		position: absolute;
		top: 0;
		right: 0;
		width: 57px;
		height: 57px;
		align-items: center;
		justify-content: center;

		@keyframes showArrow {
			0% {
				transform: translateX(0); }
			100% {
				transform: translateX(24px); } } }

	// No JS
	@at-root .no-js #{$root} {
		&__count,
		&__close-panel {
			display: none; }
		&__item {
			position: relative;

			&:target {
				#{$root} {
					&__cont {
						display: block; }
					&__title {
						border-bottom: 1px solid #e5e7eb; }
					&__close-panel {
						display: flex;
						.be-icon {
							animation: showArrow .3s forwards; } } } } } } }

// Filter(Checkbox)
.product-list-filter {
	margin-top: rem-calc(10);
	&-reset-global {
		margin-top: rem-calc(20);
		margin-bottom: 0;
		cursor: pointer;
		&,
		&--m {
			@include breakpoint(large) {
				// Hover
				&:hover,
				&:focus {
					.close-button {
						transform: rotate(90deg); } }

				.close-button {
					&:hover {
						color: $white; } } } } }
	&-reset-area {
		display: block;
		margin: 0 rem-calc(2);
		padding: rem-calc(10 2);
		width: calc(100% - 4px);
		text-align: left;
		cursor: pointer;
		@include breakpoint(large) {
			// Hover
			&:hover,
			&:focus {
				color: #db4424;
				.close-button {
					color: #db4424; } } } }

	&__item {
		margin-bottom: rem-calc(10); }
	[type=checkbox] + label {
		margin-right: 0; } }



// 價格 Filter
.filter-price {
	$root: &;
	padding: rem-calc(15 0);
	&__slider {
		&:before {
			content: "";
			position: absolute;
			top: -8px;
			left: 0;
			display: block;
			height: 16px;
			width: 100%; } }
	@include breakpoint(small only) {
		padding-bottom: rem-calc(40);
		.slider-handle {
			// 擴大選擇範圍
			&:before {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				width: 48px;
				height: 48px;
				border-radius: 100%;
				transform: translate(-50%, -50%); } } }
	// NO JS
	@at-root .no-js #{$root} {
		display: none; } }

// 用輸入的方式
.filter-price-input-box {
	margin-top: rem-calc(20);
	margin-bottom: rem-calc(16);
	display: flex;
	align-items: center;
	&__input {
		margin-bottom: 0;
		flex: 1 1 auto;
		width: calc((100% - #{rem-calc(21)}) / 2); }
	&__text {
		margin: rem-calc(0 8); }

	// Hidden input(type="number") Navagation
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none; }
	/* Firefox */
	input[type=number] {
		-moz-appearance: textfield; } }



// 左欄 廣告 Slider
.product-aside-banner {
	padding: 0;
	margin: 0;
	a {
		display: block;
		@include imgSize(715, 215); }
	@include breakpoint(small only) {
		margin-bottom: rem-calc(35 + 20); }
	@include breakpoint(medium) {
		.swiper-wrapper {
			flex-wrap: wrap; }
		.swiper-slide {
			margin-bottom: rem-calc(15); }
		.swiper-pagination {
			display: none; } }
	@include breakpoint(large) {
		// Hover
		img {
			transition: transform $tsd; }
		a {
			overflow: hidden;
			&:hover,
			&:focus {
				img {
					transform: scale(1.05); } } } } }



// 左欄 最近瀏覽過的商品 (隱藏資訊)
.product-info-tip {
	&__cont {
		font-size: rem-calc(14);
		color: $black;
		display: flex; }
	&__img-box {
		flex: 0 0 40px; }
	&__text-box {
		max-width: calc(100% - 40px);
		flex: 1 1 0px; }
	&__pd-price {
		position: relative;
		padding-left: 3.2em;
		.price {
			&__title {
				position: absolute;
				left: 0;
				top: 0; }
			&__new {
				display: inline-block; }
			&__old {
				display: inline-block;
				margin-left: rem-calc(10);
				text-decoration: line-through;
				color: #727475; } } }
	@include breakpoint(small only) {
		&__cont {
			max-height: 0;
			overflow: hidden; } }
	@include breakpoint(medium down) {
		display: none; }
	@include breakpoint(medium) {
		&__cont {
			// position: absolute
			left: 0;
			bottom: calc(100% + 10px);
			padding: rem-calc(10);
			max-width: 100%;
			width: 100%;
			background: $white;
			// display: flex
			align-items: flex-start;
			display: none;
			@include triangle('absolute', 'left', true, 'box-shadow', 'bottom'); } }

	&__btn {
		position: absolute;
		right: 0;
		top: 50%;
		padding: rem-calc(5);
		font-size: rem-calc(16);
		color: #999;
		transform: translateY(-50%);
		@include breakpoint(small only) {
			top: 5px;
			transform: none; }
		@include breakpoint(large) {
			display: none; } } }

// 最近瀏覽過的商品
.product-list-link {
	$root: &;
	&[data-layout="history"] {
		#{$root} {
			&__item {
				position: relative; }
			&__item {}
			@include breakpoint(large) {
				&__link {
					&:hover,
					&:focus {
						~ .product-info-tip {
							.product-info-tip__cont {
								display: flex; } } } } } } } }




// -------------------------------
// 右欄
// 產品主視覺
.product-visual {
	display: block;
	margin-bottom: rem-calc(20);
	@include imgSize(1100, 215);
	img {
		width: 100%; }
	@include breakpoint(medium down) {
		display: none;
 }		// +imgSize(750, 540)
	// +breakpoint(small only)
	// 	margin-left: rem-calc(-10)
	// 	margin-right: rem-calc(-10)
	// +breakpoint(medium)
	// 	margin-bottom: rem-calc(40)
	// +breakpoint(large)
	// 	+imgSize(1100, 215)

	~ .sec-title-box {
		@include breakpoint(medium only) {
			margin-bottom: 0; } } }



// 產品列表
// 已移動於 common


// 產品比較選單 (外層 | Panel)
.#{$fixedPanelMClassName} {
	$root: &;
	&[data-layout="compare"] {
		#{$root} {
			&__btn {
				background: #30353c;
				color: $white;
				padding: 16px; }
			&__ft {
				&.disabled {
					.hollow {
						border-color: $disabledDisabledColor;
						color: $disabledDisabledColor;
						&:hover,
						&:focus {
							color: $white;
							background: $disabledDisabledColor; } } } } }
		.compare-submit {
			position: relative;
			&[disabled] {
				&:hover,
				&:focus {
					.button-tip {
						display: block; } }
				@include breakpoint(medium down) {
					.button-tip {
						display: block; } } } }
		.button-tip {
			display: none;
			position: absolute;
			background: $white;
			right: 0;
			bottom: calc(100% + 20px);
			padding: rem-calc(15);
			font-size: rem-calc(14);
			white-space: nowrap;
			box-shadow: 0 0 10px rgba($black, .1);
			color: $black;
			box-sizing: border-box;
			&:before {
				content: "";
				position: absolute;
				right: 13px;
				bottom: -8px;
				display: block;
				width: 0;
				height: 0;
				border: 8px solid transparent;
				border-bottom: 0;
				border-top-color: $white; }
			@include breakpoint(medium down) {
				left: -2px;
				bottom: calc(100% + 10px);
				width: calc(80vw - 20px);
				border: 1px solid #eee;
				box-shadow: none;
				&:before,
				&:after {
					display: none; } }
			@include breakpoint(small only) {
				width: calc(100vw - 20px); } }


		@include breakpoint(medium down) {
			#{$root} {
				&__btn {
					position: fixed;
					z-index: 150;
					right: 10px;
					bottom: 55px;
					width: 36px;
					padding: rem-calc(10 4);
					text-align: center;
					i {
						display: none; } } } }
		@include breakpoint(medium only) {
			#{$root} {
				&__panel {
					position: fixed;
					z-index: 1000;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: $reveal-overlay-background;
					display: none;
					animation: showPanel .3s;
					&.is-open {
						display: block; } }
				&__hd,
				&__ft {
					padding: rem-calc(10);
					position: fixed;
					z-index: 10;
					left: 10%;
					width: 80%;
					background: $white; }
				&__hd {
					background: #e7e9ec;
					top: 50px; }
				&__ft {
					bottom: 50px;
					padding-left: rem-calc(5);
					padding-right: rem-calc(5);
					text-align: center;
					display: flex;
					.button {
						margin: rem-calc(0 5); } }
				&__cont {
					margin-top: rem-calc(50);
					overflow: auto;
					overflow-x: hidden;
					-webkit-overflow-scrolling: touch;

					position: fixed;
					left: 10%;
					width: 80%;
					top: 50px;
					height: calc(100vh - 150px);
					background: $white; }
				&__title {
					font-size: rem-calc(20);
					font-weight: bold; } } }
		@include breakpoint(large) {
			$item: &;
			position: fixed;
			z-index: 150;
			right: 0;
			top: calc(50% - 100px);
			padding-right: 50px;
			max-width: 50px;
			transition: max-width $tsd;
			animation: showPanelL .5s;
			#{$root} {
				&__panel {
					padding: rem-calc(35 30);
					min-height: 200px;
					background: $white;
					box-shadow: 0 0 10px rgba($black, .1);
					display: flex;
					align-items: center;
					transform: translateX(100%);
					transition: transform $tsd; }
				&__hd {
					display: none; }
				&__ft {
					margin-left: rem-calc(30);
					width: 150px;
					.button {
						padding: rem-calc(11);
						width: 150px;
						font-size: rem-calc(16);
						&:last-child {
							margin-bottom: 0; } }
					.compare-del-all {} }

				&__cont {
					transform: translateY(8px); }
				&__btn {
					position: absolute;
					z-index: 1;
					top: 0;
					right: 0;
					width: 50px;
					height: 100%;
					font-size: rem-calc(18);
					line-height: 1.4;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					.text {
						max-width: 100%; }
					i {
						margin-top: rem-calc(10);
						&:before {
							transition: transform $tsd; } } } }
			// Open
			&.is-open {
				max-width: 1000px;
				#{$root} {
					&__btn {
						i:before {
							transform: rotate(180deg); } } } }
			#{$root} {
				&__panel {
					&.is-open {
						transform: translateX(0); } } }
			&:hover {
				max-width: 1000px;
				#{$root} {
					&__panel {
						transform: translateX(0); }
					&__btn {
						i:before {
							transform: rotate(180deg); } } }
				// 避免平板出現點後無法收回
				@at-root html.is-mobile #{$item}:not(.is-open):hover {
					#{$root} {
						&__panel {
							transform: translateX(100%); }
						&__btn {
							i:before {
								transform: rotate(0); } } } } }
			&:focus-within {
				max-width: 1000px;
				#{$root} {
					&__panel {
						transform: translateX(0); }
					&__btn {
						i:before {
							transform: rotate(180deg); } } } } } } }
@keyframes showPanelS {
	0% {
		transform: scaleY(0);
		transform-origin: bottom center; }
	100% {
		transform: scaleY(1);
		transform-origin: bottom center; } }
@keyframes showPanelL {
	0% {
		transform: translateX(100%); }
	100% {
		transform: translateX(0); } }

// 產品比較選單 列表
.compare-nav {
	margin-bottom: 0;
	&__item {
		font-size: rem-calc(15); }
	&__link {
		color: lighten(#333333, 20%);
		display: block; }
	&__img-box {
		width: 60px;
		border: 1px solid #e4e7ec; }
	&__del {
		position: relative;
		padding: rem-calc(10);
		font-size: rem-calc(14);
		color: $white;
		.close-button {
			top: calc(50% - 11px);
			left: calc(50% - 11px);
			right: auto;
			width: 22px;
			height: 22px;
			&:before,
			&:after {
				left: calc(50% - 8px);
				width: 16px;
				height: 1px; } } }
	@include breakpoint(medium down) {
		padding: rem-calc(10);
		&__item,
		&__link {
			display: flex;
			align-items: center; }
		&__item {
			padding: rem-calc(10 0);
			justify-content: space-between;
			&:not(:last-child) {
				border-bottom: 1px solid #e9edf2; } }
		&__link {
			width: 100%; }
		&__img-box {
			margin-right: rem-calc(10);
			border: 0; }
		&__del {
			width: 40px;
			height: 40px;
			margin-right: -6px;
			.close-button {
				background: #30353c; } } }
	@include breakpoint(medium only) {
		&__img-box {
			width: 100px; } }
	@include breakpoint(large) {
		display: flex;
		&__item {
			position: relative;
			width: 160px;
			text-align: center;
			&:not(:last-child) {
				margin-right: rem-calc(10); } }
		&__del {
			background: #30353c;
			position: absolute;
			right: 0;
			top: 0;
			&:hover,
			&:focus {
				background: #db4424;
				color: $white;
				.close-button {
					&:hover,
					&:focus {
						color: $white; } } }
			&:focus {
				.close-button {
					transform: rotate(90deg); } } }
		&__img-box {
			width: 160px; } } }




// --------------------------------------------------------
// 搜尋結果 search.pug
.search-input-wrapper {
	margin: 0 auto rem-calc(50);
	max-width: 580px; }

.search-filter {
	$root: &;
	&[data-layout="searchPage"] {
		#{$root} {
			&__input {
				height: rem-calc(48);
				line-height: rem-calc(48); } }
		@include breakpoint(medium) {
			#{$root} {
				&__input {
					padding-left: rem-calc(32);
					padding-right: rem-calc(32);
					font-size: rem-calc(20); } } } } }
@include ie {
	[data-layout=searchPage].search-filter .search-filter__input {
		line-height: 1; } }

// 無搜尋結果 - 推薦
.search-recommend {
	margin-bottom: 0;
	&__item {
		display: inline-block;
		&:not(:last-child) {
			margin-right: rem-calc(10); } }
	&__link {
		display: block;
		padding: rem-calc(5); }
	@include breakpoint(large) {
		&__item {
			&:not(:last-child) {
				margin-right: rem-calc(20); } } } }
.notice-block {
	$root: &;
	&[data-layout="search"] {
		p {
			margin-bottom: 0; } } }


