.autocomplete-suggestions { 
    border: 1px solid #ccc;
    background: #FFF; 
    cursor: default; 
    overflow: auto; 
    box-shadow: 0 0 5px #cacaca;
}
.autocomplete-suggestion { 
    padding: 2px 8px; 
    white-space: nowrap; 
    overflow: hidden; 
    line-height: rem-calc(30);
    font-size: rem-calc(14);
}
.autocomplete-no-suggestion { 
    padding: 2px 5px;
}
.autocomplete-selected { 
    background: #F0F0F0; 
}
.autocomplete-suggestions strong { 
    font-weight: bold; 
    color: #5b68ec;
}
.autocomplete-group { 
    padding: 2px 5px; 
    font-weight: bold; 
    font-size: 16px; 
    color: #000; 
    display: block; 
    border-bottom: 1px solid #000; 
}