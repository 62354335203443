@mixin font-icon {
	@font-face {
		font-family: "icon";
		src: url('../fonts/icons/icon.eot?1643179280804');
		src: url('../fonts/icons/icon.eot?#iefix-1643179280804') format('embedded-opentype'),
		url('../fonts/icons/icon.woff2?1643179280804') format('woff2'),
		url('../fonts/icons/icon.woff?1643179280804') format('woff'),
		url('../fonts/icons/icon.ttf?1643179280804') format('truetype'),
		url('../fonts/icons/icon.svg?1643179280804#icon') format('svg');
		font-display: block;
		unicode-range: U+EA01-EA6A;
	}

	.be-icon:before {
		@include font-icon-style;
	}
	
	/* Glyphs list */
	
	.be-icon-alert:before {
		content: '\ea01';
	}
	
	.be-icon-appointment:before {
		content: '\ea02';
	}
	
	.be-icon-arrow-bottom:before {
		content: '\ea03';
	}
	
	.be-icon-arrow-left:before {
		content: '\ea04';
	}
	
	.be-icon-arrow-right:before {
		content: '\ea05';
	}
	
	.be-icon-arrow-top:before {
		content: '\ea06';
	}
	
	.be-icon-assembly:before {
		content: '\ea07';
	}
	
	.be-icon-b2b:before {
		content: '\ea08';
	}
	
	.be-icon-blank:before {
		content: '\ea09';
	}
	
	.be-icon-book-info:before {
		content: '\ea0a';
	}
	
	.be-icon-box:before {
		content: '\ea0b';
	}
	
	.be-icon-building:before {
		content: '\ea0c';
	}
	
	.be-icon-card:before {
		content: '\ea0d';
	}
	
	.be-icon-cart:before {
		content: '\ea0e';
	}
	
	.be-icon-channel:before {
		content: '\ea0f';
	}
	
	.be-icon-check-circle:before {
		content: '\ea10';
	}
	
	.be-icon-check:before {
		content: '\ea11';
	}
	
	.be-icon-close-circle:before {
		content: '\ea12';
	}
	
	.be-icon-close:before {
		content: '\ea13';
	}
	
	.be-icon-corner:before {
		content: '\ea14';
	}
	
	.be-icon-delet_can:before {
		content: '\ea15';
	}
	
	.be-icon-description:before {
		content: '\ea16';
	}
	
	.be-icon-discount:before {
		content: '\ea17';
	}
	
	.be-icon-fb:before {
		content: '\ea18';
	}
	
	.be-icon-gift:before {
		content: '\ea19';
	}
	
	.be-icon-google:before {
		content: '\ea1a';
	}
	
	.be-icon-hearing:before {
		content: '\ea1b';
	}
	
	.be-icon-heart-hover:before {
		content: '\ea1c';
	}
	
	.be-icon-heart:before {
		content: '\ea1d';
	}
	
	.be-icon-help:before {
		content: '\ea1e';
	}
	
	.be-icon-home-service:before {
		content: '\ea1f';
	}
	
	.be-icon-home:before {
		content: '\ea20';
	}
	
	.be-icon-ig:before {
		content: '\ea21';
	}
	
	.be-icon-info:before {
		content: '\ea22';
	}
	
	.be-icon-invoice:before {
		content: '\ea23';
	}
	
	.be-icon-label:before {
		content: '\ea24';
	}
	
	.be-icon-lens-1:before {
		content: '\ea25';
	}
	
	.be-icon-lens-2:before {
		content: '\ea26';
	}
	
	.be-icon-limited:before {
		content: '\ea27';
	}
	
	.be-icon-line:before {
		content: '\ea28';
	}
	
	.be-icon-list:before {
		content: '\ea29';
	}
	
	.be-icon-logout:before {
		content: '\ea2a';
	}
	
	.be-icon-mail:before {
		content: '\ea2b';
	}
	
	.be-icon-mb_account:before {
		content: '\ea2c';
	}
	
	.be-icon-mb_car:before {
		content: '\ea2d';
	}
	
	.be-icon-mb_customer:before {
		content: '\ea2e';
	}
	
	.be-icon-mb_icon-1:before {
		content: '\ea2f';
	}
	
	.be-icon-mb_icon-2:before {
		content: '\ea30';
	}
	
	.be-icon-mb_icon-3:before {
		content: '\ea31';
	}
	
	.be-icon-mb_icon:before {
		content: '\ea32';
	}
	
	.be-icon-mb_vip:before {
		content: '\ea33';
	}
	
	.be-icon-member:before {
		content: '\ea34';
	}
	
	.be-icon-more:before {
		content: '\ea35';
	}
	
	.be-icon-new:before {
		content: '\ea36';
	}
	
	.be-icon-notice:before {
		content: '\ea37';
	}
	
	.be-icon-notice_2:before {
		content: '\ea38';
	}
	
	.be-icon-old_machine:before {
		content: '\ea39';
	}
	
	.be-icon-open_box:before {
		content: '\ea3a';
	}
	
	.be-icon-pc:before {
		content: '\ea3b';
	}
	
	.be-icon-pen:before {
		content: '\ea3c';
	}
	
	.be-icon-person:before {
		content: '\ea3d';
	}
	
	.be-icon-phone:before {
		content: '\ea3e';
	}
	
	.be-icon-pin:before {
		content: '\ea3f';
	}
	
	.be-icon-play:before {
		content: '\ea40';
	}
	
	.be-icon-play_line:before {
		content: '\ea41';
	}
	
	.be-icon-question:before {
		content: '\ea42';
	}
	
	.be-icon-recycle:before {
		content: '\ea43';
	}
	
	.be-icon-search:before {
		content: '\ea44';
	}
	
	.be-icon-service:before {
		content: '\ea45';
	}
	
	.be-icon-setting:before {
		content: '\ea46';
	}
	
	.be-icon-share:before {
		content: '\ea47';
	}
	
	.be-icon-spec:before {
		content: '\ea48';
	}
	
	.be-icon-step1:before {
		content: '\ea49';
	}
	
	.be-icon-step2:before {
		content: '\ea4a';
	}
	
	.be-icon-step3:before {
		content: '\ea4b';
	}
	
	.be-icon-step4:before {
		content: '\ea4c';
	}
	
	.be-icon-step5:before {
		content: '\ea4d';
	}
	
	.be-icon-step6:before {
		content: '\ea4e';
	}
	
	.be-icon-store-camera:before {
		content: '\ea4f';
	}
	
	.be-icon-store-digital_camera:before {
		content: '\ea50';
	}
	
	.be-icon-store-exercise_camera:before {
		content: '\ea51';
	}
	
	.be-icon-store-exercise_earphone:before {
		content: '\ea52';
	}
	
	.be-icon-store-family_speaker:before {
		content: '\ea53';
	}
	
	.be-icon-store-handy_camera:before {
		content: '\ea54';
	}
	
	.be-icon-store-headearphone:before {
		content: '\ea55';
	}
	
	.be-icon-store-mp3:before {
		content: '\ea56';
	}
	
	.be-icon-store-mpbile:before {
		content: '\ea57';
	}
	
	.be-icon-store-oled_tv:before {
		content: '\ea58';
	}
	
	.be-icon-store-ps4:before {
		content: '\ea59';
	}
	
	.be-icon-store-recording_pen:before {
		content: '\ea5a';
	}
	
	.be-icon-store-speaker:before {
		content: '\ea5b';
	}
	
	.be-icon-store-tv:before {
		content: '\ea5c';
	}
	
	.be-icon-store-tv_4k:before {
		content: '\ea5d';
	}
	
	.be-icon-talk:before {
		content: '\ea5e';
	}
	
	.be-icon-testing:before {
		content: '\ea5f';
	}
	
	.be-icon-time:before {
		content: '\ea60';
	}
	
	.be-icon-tool:before {
		content: '\ea61';
	}
	
	.be-icon-touch:before {
		content: '\ea62';
	}
	
	.be-icon-trash:before {
		content: '\ea63';
	}
	
	.be-icon-truck-fast:before {
		content: '\ea64';
	}
	
	.be-icon-truck:before {
		content: '\ea65';
	}
	
	.be-icon-tv:before {
		content: '\ea66';
	}
	
	.be-icon-vision:before {
		content: '\ea67';
	}
	
	.be-icon-wallet:before {
		content: '\ea68';
	}
	
	.be-icon-wallet2:before {
		content: '\ea69';
	}
	
	.be-icon-youtube:before {
		content: '\ea6a';
	}
	
}

// <i>:before Style
@mixin font-icon-style {
	font-family: "icon";
	
	// Font smoothing. That was taken from TWBS
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-style: normal;
	font-weight: normal;

	// For safety - reset parent styles, that can break glyph codes
	font-variant: normal;
	text-transform: none;
	text-decoration: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	// only necessary if not using the private unicode range (firstGlyph option)
	speak: none;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;

	// fix buttons height, for twitter bootstrap
	line-height: 1em;

	// Animation center compensation - margins should be symmetric
	// remove if not needed
	margin-left: .2em;

	// you can be more comfortable with increased icons size
	// font-size: 120%;

	// Uncomment for 3D effect
	// text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}

// @include font-icon;

// SASS變數使用範例
// .icon {
//     &:before{
// 		@include font-icon-SVG檔案名稱;
//     }
// }

// 內頁使用之Style
%font-icon-style {
	@include font-icon-style;
}


$font-icon-alert: '\ea01';
@mixin font-icon-alert($extendStyle: true) {
	content: '\ea01';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-appointment: '\ea02';
@mixin font-icon-appointment($extendStyle: true) {
	content: '\ea02';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-arrow-bottom: '\ea03';
@mixin font-icon-arrow-bottom($extendStyle: true) {
	content: '\ea03';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-arrow-left: '\ea04';
@mixin font-icon-arrow-left($extendStyle: true) {
	content: '\ea04';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-arrow-right: '\ea05';
@mixin font-icon-arrow-right($extendStyle: true) {
	content: '\ea05';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-arrow-top: '\ea06';
@mixin font-icon-arrow-top($extendStyle: true) {
	content: '\ea06';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-assembly: '\ea07';
@mixin font-icon-assembly($extendStyle: true) {
	content: '\ea07';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-b2b: '\ea08';
@mixin font-icon-b2b($extendStyle: true) {
	content: '\ea08';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-blank: '\ea09';
@mixin font-icon-blank($extendStyle: true) {
	content: '\ea09';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-book-info: '\ea0a';
@mixin font-icon-book-info($extendStyle: true) {
	content: '\ea0a';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-box: '\ea0b';
@mixin font-icon-box($extendStyle: true) {
	content: '\ea0b';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-building: '\ea0c';
@mixin font-icon-building($extendStyle: true) {
	content: '\ea0c';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-card: '\ea0d';
@mixin font-icon-card($extendStyle: true) {
	content: '\ea0d';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-cart: '\ea0e';
@mixin font-icon-cart($extendStyle: true) {
	content: '\ea0e';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-channel: '\ea0f';
@mixin font-icon-channel($extendStyle: true) {
	content: '\ea0f';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-check-circle: '\ea10';
@mixin font-icon-check-circle($extendStyle: true) {
	content: '\ea10';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-check: '\ea11';
@mixin font-icon-check($extendStyle: true) {
	content: '\ea11';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-close-circle: '\ea12';
@mixin font-icon-close-circle($extendStyle: true) {
	content: '\ea12';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-close: '\ea13';
@mixin font-icon-close($extendStyle: true) {
	content: '\ea13';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-corner: '\ea14';
@mixin font-icon-corner($extendStyle: true) {
	content: '\ea14';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-delet_can: '\ea15';
@mixin font-icon-delet_can($extendStyle: true) {
	content: '\ea15';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-description: '\ea16';
@mixin font-icon-description($extendStyle: true) {
	content: '\ea16';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-discount: '\ea17';
@mixin font-icon-discount($extendStyle: true) {
	content: '\ea17';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-fb: '\ea18';
@mixin font-icon-fb($extendStyle: true) {
	content: '\ea18';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-gift: '\ea19';
@mixin font-icon-gift($extendStyle: true) {
	content: '\ea19';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-google: '\ea1a';
@mixin font-icon-google($extendStyle: true) {
	content: '\ea1a';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-hearing: '\ea1b';
@mixin font-icon-hearing($extendStyle: true) {
	content: '\ea1b';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-heart-hover: '\ea1c';
@mixin font-icon-heart-hover($extendStyle: true) {
	content: '\ea1c';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-heart: '\ea1d';
@mixin font-icon-heart($extendStyle: true) {
	content: '\ea1d';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-help: '\ea1e';
@mixin font-icon-help($extendStyle: true) {
	content: '\ea1e';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-home-service: '\ea1f';
@mixin font-icon-home-service($extendStyle: true) {
	content: '\ea1f';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-home: '\ea20';
@mixin font-icon-home($extendStyle: true) {
	content: '\ea20';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-ig: '\ea21';
@mixin font-icon-ig($extendStyle: true) {
	content: '\ea21';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-info: '\ea22';
@mixin font-icon-info($extendStyle: true) {
	content: '\ea22';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-invoice: '\ea23';
@mixin font-icon-invoice($extendStyle: true) {
	content: '\ea23';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-label: '\ea24';
@mixin font-icon-label($extendStyle: true) {
	content: '\ea24';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-lens-1: '\ea25';
@mixin font-icon-lens-1($extendStyle: true) {
	content: '\ea25';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-lens-2: '\ea26';
@mixin font-icon-lens-2($extendStyle: true) {
	content: '\ea26';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-limited: '\ea27';
@mixin font-icon-limited($extendStyle: true) {
	content: '\ea27';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-line: '\ea28';
@mixin font-icon-line($extendStyle: true) {
	content: '\ea28';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-list: '\ea29';
@mixin font-icon-list($extendStyle: true) {
	content: '\ea29';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-logout: '\ea2a';
@mixin font-icon-logout($extendStyle: true) {
	content: '\ea2a';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-mail: '\ea2b';
@mixin font-icon-mail($extendStyle: true) {
	content: '\ea2b';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-mb_account: '\ea2c';
@mixin font-icon-mb_account($extendStyle: true) {
	content: '\ea2c';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-mb_car: '\ea2d';
@mixin font-icon-mb_car($extendStyle: true) {
	content: '\ea2d';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-mb_customer: '\ea2e';
@mixin font-icon-mb_customer($extendStyle: true) {
	content: '\ea2e';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-mb_icon-1: '\ea2f';
@mixin font-icon-mb_icon-1($extendStyle: true) {
	content: '\ea2f';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-mb_icon-2: '\ea30';
@mixin font-icon-mb_icon-2($extendStyle: true) {
	content: '\ea30';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-mb_icon-3: '\ea31';
@mixin font-icon-mb_icon-3($extendStyle: true) {
	content: '\ea31';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-mb_icon: '\ea32';
@mixin font-icon-mb_icon($extendStyle: true) {
	content: '\ea32';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-mb_vip: '\ea33';
@mixin font-icon-mb_vip($extendStyle: true) {
	content: '\ea33';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-member: '\ea34';
@mixin font-icon-member($extendStyle: true) {
	content: '\ea34';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-more: '\ea35';
@mixin font-icon-more($extendStyle: true) {
	content: '\ea35';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-new: '\ea36';
@mixin font-icon-new($extendStyle: true) {
	content: '\ea36';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-notice: '\ea37';
@mixin font-icon-notice($extendStyle: true) {
	content: '\ea37';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-notice_2: '\ea38';
@mixin font-icon-notice_2($extendStyle: true) {
	content: '\ea38';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-old_machine: '\ea39';
@mixin font-icon-old_machine($extendStyle: true) {
	content: '\ea39';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-open_box: '\ea3a';
@mixin font-icon-open_box($extendStyle: true) {
	content: '\ea3a';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-pc: '\ea3b';
@mixin font-icon-pc($extendStyle: true) {
	content: '\ea3b';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-pen: '\ea3c';
@mixin font-icon-pen($extendStyle: true) {
	content: '\ea3c';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-person: '\ea3d';
@mixin font-icon-person($extendStyle: true) {
	content: '\ea3d';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-phone: '\ea3e';
@mixin font-icon-phone($extendStyle: true) {
	content: '\ea3e';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-pin: '\ea3f';
@mixin font-icon-pin($extendStyle: true) {
	content: '\ea3f';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-play: '\ea40';
@mixin font-icon-play($extendStyle: true) {
	content: '\ea40';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-play_line: '\ea41';
@mixin font-icon-play_line($extendStyle: true) {
	content: '\ea41';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-question: '\ea42';
@mixin font-icon-question($extendStyle: true) {
	content: '\ea42';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-recycle: '\ea43';
@mixin font-icon-recycle($extendStyle: true) {
	content: '\ea43';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-search: '\ea44';
@mixin font-icon-search($extendStyle: true) {
	content: '\ea44';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-service: '\ea45';
@mixin font-icon-service($extendStyle: true) {
	content: '\ea45';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-setting: '\ea46';
@mixin font-icon-setting($extendStyle: true) {
	content: '\ea46';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-share: '\ea47';
@mixin font-icon-share($extendStyle: true) {
	content: '\ea47';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-spec: '\ea48';
@mixin font-icon-spec($extendStyle: true) {
	content: '\ea48';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-step1: '\ea49';
@mixin font-icon-step1($extendStyle: true) {
	content: '\ea49';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-step2: '\ea4a';
@mixin font-icon-step2($extendStyle: true) {
	content: '\ea4a';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-step3: '\ea4b';
@mixin font-icon-step3($extendStyle: true) {
	content: '\ea4b';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-step4: '\ea4c';
@mixin font-icon-step4($extendStyle: true) {
	content: '\ea4c';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-step5: '\ea4d';
@mixin font-icon-step5($extendStyle: true) {
	content: '\ea4d';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-step6: '\ea4e';
@mixin font-icon-step6($extendStyle: true) {
	content: '\ea4e';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-store-camera: '\ea4f';
@mixin font-icon-store-camera($extendStyle: true) {
	content: '\ea4f';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-store-digital_camera: '\ea50';
@mixin font-icon-store-digital_camera($extendStyle: true) {
	content: '\ea50';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-store-exercise_camera: '\ea51';
@mixin font-icon-store-exercise_camera($extendStyle: true) {
	content: '\ea51';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-store-exercise_earphone: '\ea52';
@mixin font-icon-store-exercise_earphone($extendStyle: true) {
	content: '\ea52';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-store-family_speaker: '\ea53';
@mixin font-icon-store-family_speaker($extendStyle: true) {
	content: '\ea53';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-store-handy_camera: '\ea54';
@mixin font-icon-store-handy_camera($extendStyle: true) {
	content: '\ea54';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-store-headearphone: '\ea55';
@mixin font-icon-store-headearphone($extendStyle: true) {
	content: '\ea55';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-store-mp3: '\ea56';
@mixin font-icon-store-mp3($extendStyle: true) {
	content: '\ea56';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-store-mpbile: '\ea57';
@mixin font-icon-store-mpbile($extendStyle: true) {
	content: '\ea57';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-store-oled_tv: '\ea58';
@mixin font-icon-store-oled_tv($extendStyle: true) {
	content: '\ea58';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-store-ps4: '\ea59';
@mixin font-icon-store-ps4($extendStyle: true) {
	content: '\ea59';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-store-recording_pen: '\ea5a';
@mixin font-icon-store-recording_pen($extendStyle: true) {
	content: '\ea5a';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-store-speaker: '\ea5b';
@mixin font-icon-store-speaker($extendStyle: true) {
	content: '\ea5b';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-store-tv: '\ea5c';
@mixin font-icon-store-tv($extendStyle: true) {
	content: '\ea5c';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-store-tv_4k: '\ea5d';
@mixin font-icon-store-tv_4k($extendStyle: true) {
	content: '\ea5d';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-talk: '\ea5e';
@mixin font-icon-talk($extendStyle: true) {
	content: '\ea5e';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-testing: '\ea5f';
@mixin font-icon-testing($extendStyle: true) {
	content: '\ea5f';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-time: '\ea60';
@mixin font-icon-time($extendStyle: true) {
	content: '\ea60';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-tool: '\ea61';
@mixin font-icon-tool($extendStyle: true) {
	content: '\ea61';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-touch: '\ea62';
@mixin font-icon-touch($extendStyle: true) {
	content: '\ea62';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-trash: '\ea63';
@mixin font-icon-trash($extendStyle: true) {
	content: '\ea63';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-truck-fast: '\ea64';
@mixin font-icon-truck-fast($extendStyle: true) {
	content: '\ea64';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-truck: '\ea65';
@mixin font-icon-truck($extendStyle: true) {
	content: '\ea65';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-tv: '\ea66';
@mixin font-icon-tv($extendStyle: true) {
	content: '\ea66';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-vision: '\ea67';
@mixin font-icon-vision($extendStyle: true) {
	content: '\ea67';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-wallet: '\ea68';
@mixin font-icon-wallet($extendStyle: true) {
	content: '\ea68';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-wallet2: '\ea69';
@mixin font-icon-wallet2($extendStyle: true) {
	content: '\ea69';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}

$font-icon-youtube: '\ea6a';
@mixin font-icon-youtube($extendStyle: true) {
	content: '\ea6a';
	@if $extendStyle == true {
		@extend %font-icon-style;
	} @else {
		font-family: "icon";
	}
}
