// Navigation
.swiper-button-prev,
.swiper-button-next {
	--swiper-navigation-size: 36px;
	width: 36px;
	height: 36px;
	color: #646464;
	background: $white;
	border: 1px solid #d0d1d2;
	border-radius: 100%;
	opacity: 0.9;
	&:after {
		display: none;
	}
	&:focus {
		outline: none
	}
	
	@include breakpoint(large) {
		&[tabindex="0"] {
			transition: background-color .5s, outline-offset .2s linear;
		}
		&:after {
			content: "";
			display: block;
			position: absolute;
			left: -6px;
			top: -6px;
			width: calc(100% + 12px);
			height: calc(100% + 12px);
			border: $focusOutline;
			border-radius: 100%;
			opacity: 0;
			transform: scale(1.05);
			transition: opacity .2s, transform .2s;
		}
		&:hover,
		&:focus {
			color: $white;
			background: $primary-color;
			border-color: $primary-color;
		}
		&:focus {
			&:after {
				opacity: 1;
				transform: scale(1);
			}
		}
	}
}
.swiper-button-prev {
	@include breakpoint(large only) {
		left: 7px;
	}
	@include breakpoint(xlarge) {
		left: 30px;
	}
	&:before {
		@include font-icon-arrow-left;
		transform: translate(-1px,1px);
		@include ie {
			transform: translate(-1px,0);
		}
	}
}
.swiper-button-next {
	@include breakpoint(large only) {
		right: 7px;
	}
	@include breakpoint(xlarge) {
		right: 30px;
	}
	&:before{
		@include font-icon-arrow-right;
		transform: translate(1px,1px);
		@include ie {
			transform: translate(1px,0);
		}
	}
}

// Pagination
@include breakpoint(medium down) {
	.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
		bottom: 3px;
	}
}
.swiper-pagination-bullet {
	position: relative;
	width: 28px;
	height: 28px;
	color: #b8bab7;
	background: transparent;
	opacity: 1;
	&:before {
		content: "";
		position: absolute;
		left: calc(50% - 10px);
		top: calc(50% - 10px);
		width: 20px;
		height: 20px;
		border: 1px solid currentColor;
		border-radius: 100%;
		$scale: round(8 / 20 * 100) / 100;
		transform: scale($scale);
		transition: transform 0.3s;
	}
	&:after {
		content: "";
		position: absolute;
		left: calc(50% - 4px);
		top: calc(50% - 4px);
		width: 8px;
		height: 8px;
		background: currentColor;
		border-radius: 100%;
	}
	@include breakpoint(large) {
		&:hover,
		&:focus {
			&:after {
				color: $primary-color
			}
		}
		&:focus {
			outline: $focusOutline;
		}
	}
}
.swiper-pagination-bullet-active {
	color: $primary-color;
	&:before {
		transform: scale(1);
	}
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 0;
}