// 說明 (完整說明見) src/sass/style.sass
//	* 此檔案包含：通用於所有SCSS/SASS變數檔案
//      - 變數、mixin、fuction
//      - 所有單元都建議須要載入之樣式
//      - 避免寫實際的樣式於該檔案內（包含檔案內 @import 之其它檔案）

@charset "utf-8";


// --------------------------------------------------------
// Vendor
// --------------------------------------------------------
@import "../layout/font";
@import "../vendor/font/icons"; // 載入Icon Font 變數
@import "../foundation/foundation";


// --------------------------------------------------------
// Variable
// --------------------------------------------------------
$hoverColor: $primary-color;
$hoverColorLighten: #8a92f0;

$textColor: $body-font-color;
$borderColor: #e4e7ec;

// $borderHoverColor: lighten($primary-color, 10%);
$borderHoverColor: rgba($primary-color, .7);
$borderFocusColor: $primary-color;

$bgColor: #f2f4f7;

$visitedColor: lighten(#4f4f4f, 30%);
$focusOutline: 2px dotted $primary-color;

$tsd: 0.3s;

// 跟首頁矚目新品同樣的樣式(Hover後固定斜移物件)
$solidShadow: solid-shadow !default;

// ---------------------------------------------------
// Select Arrow
// 因為SASS刪除冒號(；)時會不小心一併刪除此處，因此轉而建立變數於 _main_style.sass
// https://stackoverflow.com/a/35505993
@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);
	@if ($index) {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}
	@return $string;
}
@function selectArrowBuild($color: '#9D9D9D') {
	$colorNormal: str-replace('' + $color, '#', '');
	$buildSvg: "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22319.999%22%20height%3D%22320%22%20viewBox%3D%220%200%20319.999%20320%22%3E%0A%20%20%3Cpath%20fill%3D%22%23#{$colorNormal}%22%20d%3D%22M-.002%20112.461l159.999%20159.7%20160-159.7V47.837l-160%20159.623L-.002%2047.837z%22%2F%3E%0A%3C%2Fsvg%3E%0A";
	@return $buildSvg;
}
$selectArrow: selectArrowBuild();



// --------------------------------------------------------
// Mixin
// --------------------------------------------------------
// Word Break
@mixin word-break {
	word-break: break-all;
	word-break: break-word;
}

// Breakpoint
@mixin breakpoint-between($start, $end) {
	@media screen and #{breakpoint($start)} and #{breakpoint($end down)} {
		@content;
	}
}

// object-fit圖片計算
// 僅提供Before內部的計算，只能在使用object-fit物件使用
@mixin imgSize($width, $height) {
	&:before{
		padding-top: round($height / $width * 100% * 100) / 100
	}
}


// Hover True Or False Media(要有符合IE的專案無法使用)
@mixin media-hover($value: true) {
	@if $value == true {
		@media (hover: hover) and (min-width: rem-calc(map-get($breakpoints,xlarge))) {
			@content;
		}
	}
	@if $value == false {
		@media (hover: none), (max-width: rem-calc(map-get($breakpoints,xlarge) - 1)) {
			@content;
		}
	}
}



// Browser
@mixin ie(){
	@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none){
		@content;
	}
}



// 遵循BEM的規則，於class增加tag（僅限於單層方式，多層不適用）
// 可使用（單層）： .component__link => a.component__link
// 不適用（多層）： .component .component__link => .component a.component__link
@mixin BEMAddTag ($tag: 'a') {
	$current: &;
	@at-root #{$tag}#{$current} {
		@content;
	}
}



// Custom Scroll Bar
$scrollbarThumbColor: $primary-color;  // 操作顏色
$scrollbarTrackColor: $white; // 滾軸背景
$scrollbarWidth: 4px; // 寬度(瀏覽器預設為15px)
$scrollbarBorderRadius: 4px; // 圓角，Firefox不支援
@mixin customScrollBar ($thumbColor: $scrollbarThumbColor, $trackColor: $scrollbarTrackColor) {
	// For Chrome, Opera, Safari, Android, iOS
	&::-webkit-scrollbar {
		width: $scrollbarWidth;
		height: $scrollbarWidth;
	}
	&::-webkit-scrollbar-track {
		background: $trackColor;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: $scrollbarBorderRadius;
		background: $thumbColor;
	}

	// For Firefox (版本必須64+)
	// 顏色：操作顏色(Thumb) 滾軸背景(Track)
	scrollbar-color: $thumbColor $trackColor;
	// 寬度只有 auto | thin | none 選項
	// auto為15px，thin為6px，none為不顯示
	scrollbar-width: thin;
}



// 生成 複數input, textarea, select 樣式
// +formMultiSelector(('text', 'textarea', 'select'))
@mixin formMultiSelector($formSelectorList) {
	$formSelector: ();
	@each $item in $formSelectorList {
		@if $item == 'text' {
			$formSelector: append($formSelector, text-inputs(), 'comma');
		} @else {
			$formSelector: append($formSelector, $item, 'comma');
		}
	}

	$selector: multiSelector($formSelector);

	#{$selector} {
		@content;
	}
}
// 生成複數Selector
// Ref: https://hugogiraudel.com/2013/07/15/understanding-sass-lists/
@function multiSelector($selectorList) {
	$selector: ();
	@each $item in $selectorList {
		$selector: append($selector, unquote(#{$item}), 'comma');
	}
	@return $selector;
}



// Toggle Show Style
// https://stackoverflow.com/a/30531678
$dOpacity: .25s;
$dDisappear: .5s;
/// @param {string} $switch - 開關class
/// @param {string} $targetEl - 要縮起展開的物件（通常為<a> ）
@mixin toggleMenuItem($switch, $targetEl) {
	// Hide
	&:not(#{$switch}) {
		#{$targetEl} {
			color: transparent;
			-webkit-speak: none;
			font-size: 0;
			margin: 0;
			opacity: 0;
			padding-top: 0;
			padding-bottom: 0;
			border-width: 0;
			line-height: 0;
			transition: opacity $dOpacity,
						font-size $dDisappear $dOpacity, 
						line-height $dDisappear $dOpacity, 
						margin $dDisappear $dOpacity,
						padding $dDisappear $dOpacity,
						color $dOpacity;
		}
	}
	// Show
	&#{$switch} {
		#{$targetEl} {
			transition: font-size $dOpacity, 
						margin $dOpacity, 
						padding $dOpacity, 
						line-height $dOpacity, 
						color $dOpacity, 
						opacity $dDisappear $dOpacity;
		}
	}
}



// 生成三角形
@mixin triangle(
	$position: 'relative', 
	$float: 'left' , 
	$boxShadow: true ,
	$type: 'box-shadow', 
	$align: 'top',
	$size: 'large'
	) {
	@if $position != 'none' {
		position: $position;
	}
	border: 1px solid #e1e1e7;
	background: $white;
	@if $boxShadow == true {
		box-shadow: 0 0 10px rgba(#333, .2);
	}
	&:before {
		content: "";
		position: absolute;
		z-index: -1;

		// 為了增加三角形border的寫法
		@if $type == 'border' {
			@if $align == 'top' {
				@if $size == 'large' {
					top: -18px;
				} @else {
					top: -9px;
				}
			} @else {
				@if $size == 'large' {
					bottom: -18px;
				} @else {
					bottom: -9px;
				}
			}
			@if $float == 'left' {
				left: 8px;
			} @else if $float == 'center' {
				left: 50%;
				margin-left: -8px;
			} @else {
				right: 9px;
			}
			display: block;
			width: 0;
			height: 0;
			border: 9px solid transparent;
			border-top-width: 0;
			border-bottom-width: 18px;
			border-bottom-color: #e1e1e7;
		}

		// 為了增加三角形box-shadow的寫法
		@if $type == 'box-shadow' {
			@if $align == 'top' {
				@if $size == 'large' {
					top: -7px;
				} @else {
					top: 0px;
				}
			} @else {
				@if $size == 'large' {
					bottom: -7px;
				} @else {
					bottom: 0px;
				}
			}
			@if $float == 'left' {
				left: 8px;
			} @else if $float == 'center' {
				left: 50%;
				margin-left: -8px;
			} @else {
				@if $align == 'top' {
					right: 10px;
				} @else {
					right: 8px;
				}
			}
			display: block;
			width: 18px;
			height: 18px;
			background: $white;
			border: 1px solid #e1e1e7;
			box-shadow: 0 0 10px rgba($black, .2);
			transform: rotate(65deg) skew(40deg);
		}

	}

	&:after {
		content: "";
		position: absolute;
		@if $align == 'top' {
			@if $size == 'large' {
				top: -16px;
			} @else {
				top: -8px;
			}
		} @else {
			@if $size == 'large' {
				bottom: -16px;
			} @else {
				bottom: -8px;
			}
		}
		@if $float == 'left' {
			left: 10px;
		} @else if $float == 'center' {
			left: 50%;
			margin-left: -8px;
		} @else {
			right: 10px;
		}
		display: block;
		width: 0;
		height: 0;
		border: 8px solid transparent;
		@if $align == 'top' {
			border-top-width: 0;
			border-bottom-width: 16px;
			border-bottom-color: $white;
		} @else {
			border-bottom-width: 0;
			border-top-width: 16px;
			border-top-color: $white;
		}
	}
}
	



// --------------------------------------------------------
// Function
// --------------------------------------------------------
// 取得Foundation設定一半的間隔(Gutter)
@function getGutterHalf($size: small) {
	@return map-get($grid-margin-gutters, $size) / 2;
}


// Mix black with another color
// ----------------------------
@function shade($color, $percent: $mix-percent-default){
	@return mix(black, $color, $percent);
}

// --------------------------------------------------------
// 設定position
// --------------------------------------------------------
@mixin position($type,
	$top: $position-default,
	$right: $position-default,
	$bottom: $position-default,
	$left: $position-default) {
	position: $type;
	$allowed_types: absolute relative fixed;
	@if not index($allowed_types, $type) {
		@warn "Unknown position: #{$type}.";
	}
	@each $data in top $top, right $right, bottom $bottom, left $left {
		#{nth($data, 1)}: nth($data, 2);
	}
}
@mixin absolute($top: $position-default, $right: $position-default, $bottom: $position-default, $left: $position-default) {
	@include position(absolute, $top, $right, $bottom, $left);
}
@mixin relative($top: $position-default, $right: $position-default, $bottom: $position-default, $left: $position-default) {
	@include position(relative, $top, $right, $bottom, $left);
}
@mixin fixed($top: $position-default, $right: $position-default, $bottom: $position-default, $left: $position-default) {
	@include position(fixed, $top, $right, $bottom, $left);
}

